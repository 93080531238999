<template>
  <div id="content" class="container">
    <nav-header :title="header_title"></nav-header>

    <div class="paper">
      <div class="pic">
        <img :src="content.banner" style="width:100%;height:100%;">
      </div>
    </div>

    <div class="det_filter">
      <div class="det_opt" @click="active=0" :class="{'active': active==0}">报名信息</div>
      <div class="det_opt" @click="active=1" :class="{'active': active==1}">报名详情</div>
    </div>

    <template v-if="active==0">
      <div class="wrap">
        <div class="w_info">
          <div class="info_t">{{content.title}}</div>
          <div class="info_d">开始时间：{{content.startTime.split(' ')[0]}}</div>
          <div class="info_d">截至时间：{{content.endTime.split(' ')[0]}}</div>
          <div class="info_d">限定人数：{{content.maxJoin || '不限'}}</div>
          <div class="info_d">报名价格：￥{{content.price}}</div>
          <div class="info_d">人工审核：{{content.audit?'是':'否'}}</div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="wrap" v-html="content.content">
      </div>
    </template>

    <div class="signBtn" @click="signin" v-if="content.price>0">立即支付</div>
    <div class="signBtn" @click="signin" v-else>立即报名</div>
  </div>
</template>
  
<script>
import NavHeader from "../../components/nav-header";
  export default {
    components: {
      NavHeader,
    },
    data() {
      return {
        header_title: "活动详情",
        active: 0,
        price: 0,
        content: {}
      }
    },
    mounted() {
      this.getDetail(this.$route.query.actId)
    },
    methods: {
      getDetail(id) {
        this.$api.Activity.Detail(id).then((res)=>{
          this.content = res.data
          console.log('detail', res.data)
        })
      },
      signin() {
        this.$router.push({
          name: "SignIn",
          query: {
            img: this.content.banner,
            title: this.content.title,
            id: this.$route.query.actId
          }
        })
      },
    }
  }
</script>
  
<style>
  .pic {
    width: 100%;
    height: 140px;
    background-color: #beb4e9;
  }

  .det_filter {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #f5f5f5;
    position: sticky;
    top: 50px;
    z-index: 9999999;
  }

  .det_opt {
    text-align: center;
    color: #252525;
    padding: 8px 0;
    font-weight: bold;
    font-size: 12px;
    display: inline-block;
    box-sizing: border-box;
  }

  .active {
    color: #d8504f;
    border-bottom: 3px solid #d8504f;
  }

  .wrap {
    padding: 25px 20px;
  }

  .w_info {
    display: flex;
    flex-direction: column;
  }

  .info_t {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .info_d {
    font-weight: bold;
    font-size: 12px;
    color: #656565;
    margin-bottom: 10px;
  }

  .signBtn {
    width: 90%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    color: #FFF;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #2e7bfd;
    border-radius: 8px;
  }
</style>